<template>
    <integer-plusminus v-model="value"></integer-plusminus>
</template>

<script>
    import { abstractField } from 'vue-form-generator/dist/vfg-core.js';
    import { IntegerPlusminus } from 'vue-integer-plusminus';

    export default {
        mixins: [ abstractField ],
        components: {
            IntegerPlusminus
        },
    };
</script>
