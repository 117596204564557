<template>
    <div class="vld-parent">
        <loading :active.sync="busy"
                 :can-cancel="true"
                 :is-full-page="true"
                 :opacity="0.9"
                 :lock-scroll="true"
        >
            <div class="spinner">
                <div class="dot1"></div>
                <div class="dot2"></div>
            </div>
        </loading>
        <div id="order_form">
            <form-wizard v-if="showForm"
                         @on-complete="onComplete"
                         @on-change="onChange"
                         color="#000"
                         error-color="#f00"
                         next-button-text="Оплачено"
            >
                <template slot="step" slot-scope="props">
                    <wizard-step :tab="props.tab"
                                 :transition="props.transition"
                                 :key="props.tab.title"
                                 :index="props.index">
                    </wizard-step>
                </template>
                <div slot="title"></div>
                <tab-content title="Ваши данные"
                             icon="ti-user"
                             :before-change="validateClientForm"
                >
                    <vue-form-generator :model="model"
                                        :schema="clientFormSchema"
                                        :options="formOptions"
                                        ref="clientForm">
                    </vue-form-generator>
                </tab-content>
                <tab-content title="Ваш заказ"
                             icon="ti-gallery"
                             :before-change="validateOrderForm"
                >
                    <div v-show="!showPaymentForm" class="flex justify-center mb-3">
                        <toggle-button
                            v-model="isComplexOrder"
                            :labels="{checked: 'Простой заказ', unchecked: 'Сложный заказ'}"
                            :color="{checked: '#b0b0b0', unchecked: '#000'}"
                            :width="260"
                            :height="40"
                            :speed="480"
                            :fontSize="16"
                        />
                    </div>
                    <ul v-if="showPaymentForm">
                        <li>Укажите, сколько пленок в вашем заказе.</li>
                        <li>Если есть подробности, напишите в комментарии.</li>
                    </ul>
                    <br/>
                    <vue-form-generator :model="model"
                                        :schema="isComplexOrder ? complexOrderFormSchema : orderFormSchema"
                                        :options="formOptions"
                                        ref="orderForm">
                    </vue-form-generator>
                </tab-content>
                <tab-content title="Оплата"
                             icon="ti-heart"
                >
                    <div v-if="showPaymentForm">
                        <div class="text-center text-xl mb-5 font-weight-bolder">Оплата {{ model.orderSum }} рублей
                        </div>
                        <div id="payment-form"></div>
                        <div id="blocker"></div>
                    </div>
                    <div v-else>
                        <h4>Спасибо!</h4>

                        <div>Ваш заказ #<span class="font-weight-bold" style="font-size: 24pt;">{{
                                model.orderNumber
                            }}</span></div>
                        <br>
                        <div>К оплате <span class="font-weight-bold" style="font-size: 14pt;">{{
                                model.orderSum
                            }}</span> рублей
                        </div>
                    </div>
                </tab-content>
                <template slot="footer" slot-scope="props">
                    <div class="wizard-footer-left"></div>

                    <div class="wizard-footer-right">
                        <wizard-button
                            v-if="showClientConfirmationButton() && !props.isLastStep && props.activeTabIndex !== 2"
                            @click.native="props.nextTab()" class="wizard-footer-right" :style="props.fillButtonStyle">
                            Далее
                        </wizard-button>

                        <wizard-button v-else-if="showPaymentConfirmationButton() && props.activeTabIndex === 2"
                                       @click.native="props.nextTab()" class="wizard-footer-right"
                                       style="background-color: red; color: white;">Оплачено!
                        </wizard-button>
                    </div>
                </template>
            </form-wizard>
            <div v-if="!showForm" id="thanks-block" class="pb-5">
                <h4>Спасибо!</h4>

                <div>Ваш заказ #<span class="font-weight-bold" style="font-size: 18pt;">{{ model.orderNumber }}</span>
                </div>
                <br>
                <div v-if="!order || !order.is_finished">
                    <ul>
                        <li>Напишите этот номер маркером на каждой катушке.</li>
                        <li>Если вы снимали не по номиналу, напишите на катушке реальный ИСО.</li>
                        <li>Положите все пленки в коробку.</li>
                    </ul>
                </div>
                <div v-else-if="order && order.show_closing_date">
                    <div>Фотопленку храним до <span class="font-weight-bold" style="font-size: 14pt;">{{
                            order.closed_at
                        }}</span>.
                        <div>Не забудьте забрать - ждём вас!</div>
                    </div>
                </div>
                <br>
                <div>Подписывайтесь в социальных сетях!</div>
                <ul class="underline">
                    <li><a href="https://instagram.com/luch.lab">instagram/luch.lab</a></li>
                    <li><a href="https://vk.com/luchlab">vk.com/luchlab</a></li>
                    <li><a href="https://www.facebook.com/luch.lab">facebook.com/luch.lab</a></li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import VueFormGenerator from 'vue-form-generator/dist/vfg-core.js';
// let resources = {
//     fieldIsRequired: "This field is required!",
//     invalidFormat: "Invalid format!",
//
//     numberTooSmall: "The number is too small! Minimum: {0}",
//     numberTooBig: "The number is too big! Maximum: {0}",
//     invalidNumber: "Invalid number",
//     invalidInteger: "The value is not an integer",
//
//     textTooSmall: "The length of text is too small! Current: {0}, Minimum: {1}",
//     textTooBig: "The length of text is too big! Current: {0}, Maximum: {1}",
//     thisNotText: "This is not a text!",
//
//     thisNotArray: "This is not an array!",
//
//     selectMinItems: "Select minimum {0} items!",
//     selectMaxItems: "Select maximum {0} items!",
//
//     invalidDate: "Invalid date!",
//     dateIsEarly: "The date is too early! Current: {0}, Minimum: {1}",
//     dateIsLate: "The date is too late! Current: {0}, Maximum: {1}",
//
//     invalidEmail: "Invalid e-mail address!",
//     invalidURL: "Invalid URL!",
//
//     invalidCard: "Invalid card format!",
//     invalidCardNumber: "Invalid card number!",
//
//     invalidTextContainNumber: "Invalid text! Cannot contains numbers or special characters",
//     invalidTextContainSpec: "Invalid text! Cannot contains special characters"
// };
Object.assign(VueFormGenerator.validators.resources, {
    "fieldIsRequired": "Обязательное поле",
    "invalidEmail": "Проверьте e-mail",
    "invalidNumber": "Введите число",
})

import VueFormWizard from 'vue-form-wizard';
import {nekode} from './../http-common.js';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import "vue-form-generator/dist/vfg-core.css";
import fieldPlusMinus from "./fieldPlusMinus";
import ToggleButton from 'vue-js-toggle-button';
import VueClipboard from 'vue-clipboard2';
import VueTheMask from 'vue-the-mask';
import VfgTheMask from './VfgTheMask';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(ToggleButton)
Vue.use(VueClipboard)
Vue.use(VueFormGenerator)
Vue.use(VueTheMask)
Vue.component("fieldPlusMinus", fieldPlusMinus)
Vue.component('field-vfg-the-mask', VfgTheMask)

function updateModel(model, data) {
    return Object.assign(model, {
        orderId: data.id,
        orderNumber: data.number,
        orderSum: data.sum,
        orderUrl: data.url,
        orderPayUrl: data.pay_url,
    });
}

function updateParams(model, extraParams = {}) {
    let params = {
        boxId: model.boxId,
        client: {
            name: model.name,
            email: model.email,
            phone: model.phone,
            details: model.clientDetails,
            is_new_client: model.isNewClient,
        },
        order: {
            number: model.orderNumber,
            filmGeneralCount: model.filmGeneralCount,
            filmCount: model.filmCount,
            wideFilmCount: model.wideFilmCount,
            filmEcnCount: model.filmEcnCount,
            wideFilmEcnCount: model.wideFilmEcnCount,
            filmBwCount: model.filmBwCount,
            wideFilmBwCount: model.wideFilmBwCount,
            filmE6Count: model.filmE6Count,
            wideFilmE6Count: model.wideFilmE6Count,
            filmOrwoCount: model.filmOrwoCount,

            expressColor: model.expressColor,
            expressEcn: model.expressEcn,
            expressBw: model.expressBw,

            developColorCount: model.developColorCount,
            developEcnCount: model.developEcnCount,
            developBwCount: model.developBwCount,
            developSlide: model.developSlide,
            developWideColor: model.developWideColor,
            developWideEcn: model.developWideEcn,
            developWideBw: model.developWideBw,
            developWideSlide: model.developWideSlide,

            scan135Count: model.scan135Count,
            scan120Count: model.scan120Count,
            scanPerforationCount: model.scanPerforationCount,
            scanPerforationFrame: model.scanPerforationFrame,
            scanSlideFrameCount: model.scanSlideFrameCount,
            scanBoundless: model.scanBoundless,

            videoTape: model.videoTape,
            packingCount: model.packingCount,
            pushPullCount: model.pushPullCount,
            tiffCount: model.tiffCount,
            details: model.orderDetails,
            doNotNeedFilm: !model.needFilm,
            discount: model.discount,
        }
    }

    Object.assign(params, params, extraParams);

    return params;
}

export default {
    components: {
        Loading,
        VueFormWizard,
    },
    props: {
        apiUrl: {
            type: String,
            default: ''
        },
        boxId: {
            type: Number,
            default: 0
        },
        order: {
            type: Object,
            required: false,
        },
        labels: {
            type: Object,
            required: true,
        },
        showPaymentForm: {
            type: Boolean,
            required: false,
            default: false,
        },
        title: {
            type: String,
            default: 'Лаба ЛУЧ'
        },
        subtitle: {
            type: String,
            default: 'Форма заказа'
        },
    },
    data() {
        return {
            busy: false,
            isComplexOrder: false,
            isFinished: false,
            cardNumber: '5536913961343536',
            emailIsValid: this.showPaymentForm,
            model: {
                isNewClient: null,
                boxId: this.boxId,
                name: '',
                email: '',
                clientDetails: '',
                orderId: 0,
                orderNumber: 0,
                orderUrl: '',
                orderPayUrl: '',
                orderDetails: '',
                orderSum: 0,
                filmGeneralCount: 0,
                filmCount: 0,
                wideFilmCount: 0,
                filmEcnCount: 0,
                wideFilmEcnCount: 0,
                filmBwCount: 0,
                wideFilmBwCount: 0,
                filmE6Count: 0,
                wideFilmE6Count: 0,
                filmOrwoCount: 0,

                expressColor: 0,
                expressEcn: 0,
                expressBw: 0,

                developColorCount: 0,
                developEcnCount: 0,
                developBwCount: 0,
                developSlide: 0,
                developWideColor: 0,
                developWideEcn: 0,
                developWideBw: 0,
                developWideSlide: 0,

                scan135Count: 0,
                scan120Count: 0,
                scanPerforationCount: 0,
                scanPerforationFrame: 0,
                scanPerforationWideFrame: 0,
                scanSlideFrameCount: 0,
                scanBoundless: 0,

                videoTape: 0,
                pushPullCount: 0,
                tiffCount: 0,
                packingCount: 0,
                needFilm: false,
                discount: 0,
            },
            formOptions: {
                validationErrorClass: "has-error",
                validationSuccessClass: "has-success",
                validateAfterChanged: true
            },

            clientFormSchema: {
                fields: [
                    {
                        type: "radios",
                        model: "isNewClient",
                        visible: this.showClientTypeSelector,
                        values: [
                            {
                                name: 'В первый раз',
                                value: true,
                            },
                            {
                                name: 'Постоянный клиент',
                                value: false,
                            },
                        ]
                    },
                    {
                        type: "label",
                        label: "ВАЖНО! Заполняйте эти поля внимательно, иначе нам будет некуда отправить ваши фотографии.",
                        visible: this.isClientTypeSelected && this.isVisibleForNewClient,
                    },
                    {
                        type: "input",
                        inputType: "text",
                        label: "Имя",
                        model: "name",
                        required: true,
                        visible: this.isClientTypeSelected && this.isVisibleForNewClient,
                        validator: VueFormGenerator.validators.string,
                        styleClasses: 'flex flex-col mb-4'
                    },
                    {
                        type: "input",
                        inputType: "email",
                        label: "E-mail",
                        model: "email",
                        required: true,
                        visible: this.isClientTypeSelected,
                        validator: this.showPaymentForm
                            ? VueFormGenerator.validators.email
                            : this.validateEmail,
                        styleClasses: 'flex flex-col mb-4',
                    },
                    {
                        type: "vfg-the-mask",
                        mask: "# (###) ###-##-##",
                        label: "Номер телефона",
                        model: "phone",
                        required: true,
                        visible: this.isClientTypeSelected && this.isVisibleForNewClient,
                        styleClasses: 'flex flex-col mb-4'
                    },
                    {
                        type: "textArea",
                        label: "Другой способ связи",
                        model: "clientDetails",
                        required: false,
                        visible: this.isClientTypeSelected && this.isVisibleForNewClient,
                        validator: VueFormGenerator.validators.string,
                        rows: 3,
                        styleClasses: 'flex flex-col mb-4'
                    }
                ]
            },
            orderFormSchema: {
                fields: [
                    // только для спотов
                    {
                        type: "plusMinus",
                        label: this.getLabel('film_general_count'),
                        model: "filmGeneralCount",
                        hint: 'Проявка и сканирование цветной (C-41), черно-белой или кинопленки (ECN-II)',
                        visible: this.showPaymentForm
                    },
                    // всё ниже - для лабы
                    {
                        type: "plusMinus",
                        label: this.getLabel('film_count'),
                        model: "filmCount",
                        hint: 'Проявка и сканирование узкой (тип 135) цветной негативной фотопленки',
                        visible: !this.showPaymentForm
                    },
                    {
                        type: "plusMinus",
                        label: this.getLabel('film_ecn_count'),
                        model: "filmEcnCount",
                        hint: 'Проявка и сканирование узкой (тип 135) негативной фотопленки с сажевым слоем',
                        visible: !this.showPaymentForm
                    },
                    {
                        type: "plusMinus",
                        label: this.getLabel('film_bw_count'),
                        model: "filmBwCount",
                        hint: 'Проявка и сканирование узкой (тип 135) черно-белой негативной фотопленки',
                        visible: !this.showPaymentForm
                    },
                    {
                        type: "plusMinus",
                        label: this.getLabel('tiff'),
                        model: "tiffCount",
                        hint: 'Формат файлов для расширенной цифровой постобработки',
                    },
                    {
                        type: "plusMinus",
                        label: this.getLabel('packing_count'),
                        model: "packingCount",
                        hint: 'Упаковка в сливер из кальки для наилучшей сохранности негативов',
                    },
                    {
                        type: "checkbox",
                        label: `Буду забирать плёнку
                                    <br/>
                                    <div class="text-xs">Нажмите галочку, если вам нужна фотоплёнка</div>`,
                        model: "needFilm",
                        default: false
                    },
                    // {
                    //     type: "input",
                    //     inputType: "number",
                    //     label: "Скидка, в рублях",
                    //     model: "discount",
                    //     required: false,
                    //     default: 0,
                    //     validator: VueFormGenerator.validators.number,
                    //     min: 0,
                    //     visible: this.isVisibleForComplexOrder,
                    // },
                    {
                        type: "textArea",
                        label: "Комментарий к заказу",
                        model: "orderDetails",
                        required: false,
                        validator: VueFormGenerator.validators.string,
                        rows: 3,
                        styleClasses: 'flex flex-col mb-4'
                    },
                    {
                        type: "input",
                        inputType: "hidden",
                        model: "orderId",
                    },
                ],
            },
            complexOrderFormSchema: {
                groups: [
                    {
                        legend: "Проявка + сканирование",
                        fields: [
                            {
                                type: "plusMinus",
                                label: this.getLabel('film_count'),
                                model: "filmCount",
                                hint: 'Проявка и сканирование узкой (тип 135) цветной негативной фотопленки',
                            },
                            {
                                type: "plusMinus",
                                label: this.getLabel('film_ecn_count'),
                                model: "filmEcnCount",
                                hint: 'Проявка и сканирование узкой (тип 135) негативной фотопленки с сажевым слоем',
                            },
                            {
                                type: "plusMinus",
                                label: this.getLabel('film_bw_count'),
                                model: "filmBwCount",
                                hint: 'Проявка и сканирование узкой (тип 135) черно-белой негативной фотопленки',
                            },
                            {
                                type: "plusMinus",
                                label: this.getLabel('film_e6_count'),
                                model: "filmE6Count",
                                hint: 'Проявка и сканирование узкой (тип 135) цветной слайдовой фотопленки',
                            },
                            {
                                type: "plusMinus",
                                label: this.getLabel('wide_film_count'),
                                model: "wideFilmCount",
                                hint: 'Проявка и сканирование широкой (тип 120) цветной негативной фотопленки',
                            },
                            {
                                type: "plusMinus",
                                label: this.getLabel('wide_film_ecn_count'),
                                model: "wideFilmEcnCount",
                                hint: 'Проявка и сканирование широкой (тип 120) негативной фотопленки с сажевым слоем',
                            },
                            {
                                type: "plusMinus",
                                label: this.getLabel('wide_film_bw_count'),
                                model: "wideFilmBwCount",
                                hint: 'Проявка и сканирование широкой (тип 120) черно-белой негативной фотопленки',
                            },
                            {
                                type: "plusMinus",
                                label: this.getLabel('wide_film_e6_count'),
                                model: "wideFilmE6Count",
                                hint: 'Проявка и сканирование широкой (тип 120) цветной слайдовой фотопленки',
                            },
                        ]
                    },
                    {
                        legend: "Экспресс",
                        fields: [
                            {
                                type: "plusMinus",
                                label: this.getLabel('express_color'),
                                model: "expressColor",
                            },
                            {
                                type: "plusMinus",
                                label: this.getLabel('express_ecn'),
                                model: "expressEcn",
                            },
                            {
                                type: "plusMinus",
                                label: this.getLabel('express_bw'),
                                model: "expressBw",
                            },
                        ],
                    },
                    {
                        legend: 'Только проявка',
                        fields: [
                            {
                                type: "plusMinus",
                                label: this.getLabel('develop_color_count'),
                                model: "developColorCount",
                            },
                            {
                                type: "plusMinus",
                                label: this.getLabel('develop_ecn_count'),
                                model: "developEcnCount",
                            },
                            {
                                type: "plusMinus",
                                label: this.getLabel('develop_bw_count'),
                                model: "developBwCount",
                            },
                        ],
                    },
                    {
                        legend: 'Только сканирование',
                        fields: [
                            {
                                type: "plusMinus",
                                label: this.getLabel('scan_135_count'),
                                model: "scan135Count",
                            },
                            {
                                type: "plusMinus",
                                label: this.getLabel('scan_120_count'),
                                model: "scan120Count",
                            },
                            {
                                type: "plusMinus",
                                label: this.getLabel('scan_perforation_count'),
                                model: "scanPerforationCount",
                            },
                            {
                                type: "plusMinus",
                                label: this.getLabel('scan_perforation_frame_count'),
                                model: "scanPerforationFrame",
                            },
                            {
                                type: "plusMinus",
                                label: this.getLabel('scan_perforation_wide_frame'),
                                model: "scanPerforationWideFrame",
                            },
                            {
                                type: "plusMinus",
                                label: this.getLabel('scan_slide_frame_count'),
                                model: "scanSlideFrameCount",
                            },
                            {
                                type: "plusMinus",
                                label: this.getLabel('scan_boundless'),
                                model: "scanBoundless",
                            },
                        ],
                    },
                    {
                        legend: 'Дополнительные услуги',
                        fields: [
                            {
                                type: "plusMinus",
                                label: this.getLabel('video_tape'),
                                model: "videoTape",
                            },
                            {
                                type: "plusMinus",
                                label: this.getLabel('push_pull_count'),
                                model: "pushPullCount",
                                hint: 'Компенсация экспозиции на этапе проявки',
                            },
                            {
                                type: "plusMinus",
                                label: this.getLabel('tiff'),
                                model: "tiffCount",
                                hint: 'Формат файлов для расширенной цифровой постобработки',
                            },
                            {
                                type: "plusMinus",
                                label: this.getLabel('packing_count'),
                                model: "packingCount",
                                hint: 'Упаковка в сливер из кальки для наилучшей сохранности негативов',
                            },
                            {
                                type: "checkbox",
                                label: `Буду забирать плёнку
                                    <br/>
                                    <div class="text-xs">Нажмите галочку, если вам нужна фотоплёнка</div>`,
                                model: "needFilm",
                                default: false
                            },
                            {
                                type: "textArea",
                                label: "Комментарий к заказу",
                                model: "orderDetails",
                                required: false,
                                validator: VueFormGenerator.validators.string,
                                rows: 3,
                                styleClasses: 'flex flex-col mb-4'
                            },
                            {
                                type: "input",
                                inputType: "hidden",
                                model: "orderId",
                            },
                        ],
                    },
                ]
            }
        }
    },
    computed: {
        showForm: function () {
            return !this.isFinished
                && !this.hasOrder();
        },
        count: function () {
            return this.isComplexOrder
                ? [
                    this.model.filmCount, this.model.wideFilmCount, this.model.filmEcnCount, this.model.wideFilmEcnCount,
                    this.model.filmE6Count, this.model.wideFilmE6Count, this.model.filmOrwoCount,
                    this.model.filmBwCount, this.model.wideFilmBwCount, this.model.expressColor, this.model.expressEcn, this.model.expressBw,
                    this.model.developColorCount, this.model.developEcnCount, this.model.developBwCount, this.model.developSlide, this.model.developWideColor, this.model.developWideEcn, this.model.developWideBw, this.model.developWideSlide,
                    this.model.scan135Count, this.model.scan120Count, this.model.scanPerforationCount,
                    this.model.scanPerforationFrame, this.model.scanPerforationWideFrame, this.model.scanSlideFrameCount, this.model.scanBoundless, this.model.pushPullCount, this.model.videoTape, this.model.tiffCount, this.model.packingCount,
                ].reduce((a, b) => a + b, 0)
                : this.model.filmGeneralCount + this.model.filmCount + this.model.filmEcnCount + this.model.filmBwCount + this.model.pushPullCount +
                + this.model.tiffCount + this.model.packingCount;
        }
    },
    mounted() {
        if (this.hasOrder()) {
            this.model = updateModel(
                this.model,
                this.order
            );
        }
    },
    methods: {
        notifyError: function (error) {
            window.Bugsnag.setUser('x', this.model.email, this.model.name)
            window.Bugsnag.addMetadata('data', this.model)
            window.Bugsnag.notify(error)
        },
        onComplete: function () {
            this.isFinished = true;
        },
        onChange: function (prevIndex, nextIndex) {
            let requestParams = updateParams(this.model);

            // client tab -> order details tab
            if (prevIndex === 0 && nextIndex === 1) {
                this.busy = true;
                // create
                nekode(this.apiUrl)
                    .get('order/create')
                    .then(response => {
                        this.model = updateModel(
                            this.model,
                            response.data.data
                        );

                        // update
                        nekode(this.apiUrl)
                            .put('order/' + this.model.orderId, requestParams)
                            .then(response => {
                                this.model = updateModel(
                                    this.model,
                                    response.data.data
                                );

                                this.busy = false;
                            }).catch(error => {
                                // todo reload page
                                this.notifyError(error);
                            })
                    }).catch(error => {
                        this.notifyError(error);
                    })
            }

            // order details tab -> payment tab
            if (prevIndex === 1 && nextIndex === 2) {
                this.busy = true;

                // если без формы - сразу как будто "оплачено"
                if (!this.showPaymentForm) {
                    requestParams = updateParams(this.model, {
                        is_paid: true,
                    });
                } else {
                    requestParams = updateParams(this.model, {
                        need_pay: true,
                    });
                }

                nekode(this.apiUrl)
                    .put('order/' + this.model.orderId, requestParams)
                    .then(response => {
                        this.model = updateModel(
                            this.model,
                            response.data.data
                        );

                        if (this.showPaymentForm && response.data.payment_token) {
                            const checkout = new window.YooMoneyCheckoutWidget({
                                confirmation_token: response.data.payment_token,
                                return_url: this.model.orderPayUrl,
                                error_callback(error) {
                                    window.Bugsnag.notify(error);
                                }
                            });

                            checkout.render('payment-form')
                                .then(function () {
                                    this.busy = false;
                                }.bind(this));
                        } else {
                            this.busy = false;
                        }
                    })
                    .catch(error => {
                        this.notifyError(error);
                    })
            }
        },
        validateClientForm: function () {
            return this.$refs.clientForm.validate()
        },
        validateEmail: function(value, field, model, messages) {
            this.emailIsValid = false

            const clientSide = VueFormGenerator.validators.email(value, field, model, messages)

            if (clientSide !== undefined && clientSide.length > 0) {
                return clientSide
            } else {
                this.emailIsValid = true
            }

            if (this.model.isNewClient) {
                return clientSide
            }

            return nekode(this.apiUrl)
                .post(
                    'client/check',
                    updateParams(this.model)
                )
                .then(response => {
                    this.emailIsValid = true
                    return [];
                }).catch(error => {
                    this.emailIsValid = false

                    if (error.response.status === 400) {
                        const messages = JSON.parse(error.response.data.message)

                        return messages['email'];
                    }

                    this.notifyError(error);

                    return ['Ошибка']
                })
        },
        validateOrderForm: function () {
            return this.validateOrderDetails() && this.$refs.orderForm.validate();
        },
        validateOrderDetails: function () {
            return this.count > 0;
        },
        showClientTypeSelector: function () {
            return !this.isClientTypeSelected();
        },
        showClientConfirmationButton: function () {
            if (this.showClientTypeSelector()) {
                return false
            }

            if (this.model.isNewClient) {
                return true
            }

            return this.emailIsValid
        },
        showPaymentConfirmationButton: function () {
            return !this.showPaymentForm;
        },
        isClientTypeSelected: function () {
            return typeof this.model.isNewClient === 'boolean';
        },
        isVisibleForNewClient: function () {
            return this.model.isNewClient === true;
        },
        isVisibleForComplexOrder: function () {
            return this.isComplexOrder;
        },
        hasOrder: function () {
            return this.order !== undefined && this.order.number !== undefined;
        },
        getLabel: function (key) {
            return this.labels && this.labels[key] ? this.labels[key] : '';
        }
    }
}
</script>
<style>
.vue-form-generator legend {
    font-size: 15pt;
    font-weight: bold;
}

.wizard-header {
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
}

.wizard-icon-circle.checked {
    color: #000;
}

.vue-form-wizard .wizard-tab-content {
    padding: 30px 20px 10px;
    margin: 0 2rem;
}

@media (max-width: 376px) {
    .vue-form-wizard .wizard-tab-content {
        padding: 30px 0 10px;
        margin: 0 1rem;
    }
}

@media (max-width: 321px) {
    .vue-form-wizard .wizard-tab-content {
        padding: 30px 0 10px;
        margin: 0 10px;
    }
}

#thanks-block {
    margin: 1rem 2rem;
}

.vue-form-generator .field-checkbox input {
    margin-left: 12px;
}

.form-group.field-checkbox {
    display: flex;
    flex-wrap: wrap;
}

.form-group.field-checkbox label {
    order: 1;
    padding-left: 10px;
}

.form-group.field-checkbox input {
    margin-top: 5px;
    margin-left: 7px;
    margin-right: 7px;
    transform: scale(2);
}

.radio-list {
    margin: 10px auto 0;
}

.radio-list input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
}

.radio-list label {
    cursor: pointer;
    color: white;
    background-color: black;
    margin-bottom: 10px;
    padding: 10px 20px;
    text-align: center;
    font-family: sans-serif, Arial;
    font-size: 18px;
    font-weight: bold;
    border-radius: 4px;
}

.radio-list label:hover {
    color: black;
    background-color: white;
    outline: 1px solid black;
}

/* errors */
.errors span {
    color: red;
}

/* payment form */
#blocker {
    position: absolute;
    bottom: 52px;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 50000;
    height: 62px;
}

/* spinner */
.spinner {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
    text-align: center;
    z-index: 100000;

    -webkit-animation: sk-rotate 2.0s infinite linear;
    animation: sk-rotate 2.0s infinite linear;
}

.dot1, .dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: #333;
    border-radius: 100%;

    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.dot2 {
    top: auto;
    bottom: 0;
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

@-webkit-keyframes sk-rotate {
    100% {
        -webkit-transform: rotate(360deg)
    }
}

@keyframes sk-rotate {
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg)
    }
}

@-webkit-keyframes sk-bounce {
    0%, 100% {
        -webkit-transform: scale(0.0)
    }
    50% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bounce {
    0%, 100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    }
    50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}
</style>
