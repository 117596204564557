/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('order-form', require('./components/OrderForm.vue').default);

window.Bugsnag = require('@bugsnag/js')
import BugsnagPluginVue from '@bugsnag/plugin-vue'

window.Bugsnag.start({
    apiKey: '8e7096675090017d09006c7892849894',
    plugins: [new BugsnagPluginVue()]
})

const app = new Vue({
    el: "#app",
});

