var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("the-mask", {
        staticClass: "form-control",
        attrs: {
          mask: _vm.schema.mask,
          placeholder: _vm.schema.placeholder,
          tokens: _vm.schema.tokens,
          required: _vm.schema.required,
        },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }