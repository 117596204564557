var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vld-parent" },
    [
      _c(
        "loading",
        {
          attrs: {
            active: _vm.busy,
            "can-cancel": true,
            "is-full-page": true,
            opacity: 0.9,
            "lock-scroll": true,
          },
          on: {
            "update:active": function ($event) {
              _vm.busy = $event
            },
          },
        },
        [
          _c("div", { staticClass: "spinner" }, [
            _c("div", { staticClass: "dot1" }),
            _vm._v(" "),
            _c("div", { staticClass: "dot2" }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "order_form" } },
        [
          _vm.showForm
            ? _c(
                "form-wizard",
                {
                  attrs: {
                    color: "#000",
                    "error-color": "#f00",
                    "next-button-text": "Оплачено",
                  },
                  on: {
                    "on-complete": _vm.onComplete,
                    "on-change": _vm.onChange,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "step",
                        fn: function (props) {
                          return [
                            _c("wizard-step", {
                              key: props.tab.title,
                              attrs: {
                                tab: props.tab,
                                transition: props.transition,
                                index: props.index,
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "footer",
                        fn: function (props) {
                          return [
                            _c("div", { staticClass: "wizard-footer-left" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "wizard-footer-right" },
                              [
                                _vm.showClientConfirmationButton() &&
                                !props.isLastStep &&
                                props.activeTabIndex !== 2
                                  ? _c(
                                      "wizard-button",
                                      {
                                        staticClass: "wizard-footer-right",
                                        style: props.fillButtonStyle,
                                        nativeOn: {
                                          click: function ($event) {
                                            return props.nextTab()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                        Далее\n                    "
                                        ),
                                      ]
                                    )
                                  : _vm.showPaymentConfirmationButton() &&
                                    props.activeTabIndex === 2
                                  ? _c(
                                      "wizard-button",
                                      {
                                        staticClass: "wizard-footer-right",
                                        staticStyle: {
                                          "background-color": "red",
                                          color: "white",
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return props.nextTab()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "Оплачено!\n                    "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2829433605
                  ),
                },
                [
                  _vm._v(" "),
                  _c("div", { attrs: { slot: "title" }, slot: "title" }),
                  _vm._v(" "),
                  _c(
                    "tab-content",
                    {
                      attrs: {
                        title: "Ваши данные",
                        icon: "ti-user",
                        "before-change": _vm.validateClientForm,
                      },
                    },
                    [
                      _c("vue-form-generator", {
                        ref: "clientForm",
                        attrs: {
                          model: _vm.model,
                          schema: _vm.clientFormSchema,
                          options: _vm.formOptions,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "tab-content",
                    {
                      attrs: {
                        title: "Ваш заказ",
                        icon: "ti-gallery",
                        "before-change": _vm.validateOrderForm,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.showPaymentForm,
                              expression: "!showPaymentForm",
                            },
                          ],
                          staticClass: "flex justify-center mb-3",
                        },
                        [
                          _c("toggle-button", {
                            attrs: {
                              labels: {
                                checked: "Простой заказ",
                                unchecked: "Сложный заказ",
                              },
                              color: { checked: "#b0b0b0", unchecked: "#000" },
                              width: 260,
                              height: 40,
                              speed: 480,
                              fontSize: 16,
                            },
                            model: {
                              value: _vm.isComplexOrder,
                              callback: function ($$v) {
                                _vm.isComplexOrder = $$v
                              },
                              expression: "isComplexOrder",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.showPaymentForm
                        ? _c("ul", [
                            _c("li", [
                              _vm._v("Укажите, сколько пленок в вашем заказе."),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _vm._v(
                                "Если есть подробности, напишите в комментарии."
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("vue-form-generator", {
                        ref: "orderForm",
                        attrs: {
                          model: _vm.model,
                          schema: _vm.isComplexOrder
                            ? _vm.complexOrderFormSchema
                            : _vm.orderFormSchema,
                          options: _vm.formOptions,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "tab-content",
                    { attrs: { title: "Оплата", icon: "ti-heart" } },
                    [
                      _vm.showPaymentForm
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-center text-xl mb-5 font-weight-bolder",
                              },
                              [
                                _vm._v(
                                  "Оплата " +
                                    _vm._s(_vm.model.orderSum) +
                                    " рублей\n                    "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { attrs: { id: "payment-form" } }),
                            _vm._v(" "),
                            _c("div", { attrs: { id: "blocker" } }),
                          ])
                        : _c("div", [
                            _c("h4", [_vm._v("Спасибо!")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v("Ваш заказ #"),
                              _c(
                                "span",
                                {
                                  staticClass: "font-weight-bold",
                                  staticStyle: { "font-size": "24pt" },
                                },
                                [_vm._v(_vm._s(_vm.model.orderNumber))]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v("К оплате "),
                              _c(
                                "span",
                                {
                                  staticClass: "font-weight-bold",
                                  staticStyle: { "font-size": "14pt" },
                                },
                                [_vm._v(_vm._s(_vm.model.orderSum))]
                              ),
                              _vm._v(" рублей\n                    "),
                            ]),
                          ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.showForm
            ? _c(
                "div",
                { staticClass: "pb-5", attrs: { id: "thanks-block" } },
                [
                  _c("h4", [_vm._v("Спасибо!")]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v("Ваш заказ #"),
                    _c(
                      "span",
                      {
                        staticClass: "font-weight-bold",
                        staticStyle: { "font-size": "18pt" },
                      },
                      [_vm._v(_vm._s(_vm.model.orderNumber))]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  !_vm.order || !_vm.order.is_finished
                    ? _c("div", [_vm._m(0)])
                    : _vm.order && _vm.order.show_closing_date
                    ? _c("div", [
                        _c("div", [
                          _vm._v("Фотопленку храним до "),
                          _c(
                            "span",
                            {
                              staticClass: "font-weight-bold",
                              staticStyle: { "font-size": "14pt" },
                            },
                            [_vm._v(_vm._s(_vm.order.closed_at))]
                          ),
                          _vm._v(".\n                    "),
                          _c("div", [
                            _vm._v("Не забудьте забрать - ждём вас!"),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("div", [_vm._v("Подписывайтесь в социальных сетях!")]),
                  _vm._v(" "),
                  _vm._m(1),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [_vm._v("Напишите этот номер маркером на каждой катушке.")]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Если вы снимали не по номиналу, напишите на катушке реальный ИСО."
        ),
      ]),
      _vm._v(" "),
      _c("li", [_vm._v("Положите все пленки в коробку.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "underline" }, [
      _c("li", [
        _c("a", { attrs: { href: "https://instagram.com/luch.lab" } }, [
          _vm._v("instagram/luch.lab"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://vk.com/luchlab" } }, [
          _vm._v("vk.com/luchlab"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://www.facebook.com/luch.lab" } }, [
          _vm._v("facebook.com/luch.lab"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }